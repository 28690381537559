import React from "react";

const Hero = () => {
  return (
    <div className="max-w-[1200px] mx-auto my-6 p-4 bg-[#000] h-[56vh] rounded-lg shadow-xl text-white">
    
    </div>
  );
};

export default Hero;